<template>
  <section class="app-container">
    <div class="mb12" v-if="!collapse">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <a-form-item label="Task Type" name="taskType" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.taskType"
                :options="taskTypeOps"
                placeholder="请选择"
                mode="multiple"
                :maxTagCount="1"
                showArrow
                allowClear
                optionFilterProp="label"
            >
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="Task状态" name="taskStatus" class="rms-form-item">
            <SelectWithAll
                v-model:value="formState.taskStatus"
                :options="taskStatusOps"
                placeholder="请选择"
                mode="multiple"
                :maxTagCount="1"
                showArrow
                allowClea
            >
            </SelectWithAll>
          </a-form-item>
        </a-form>
      </FilterLayout>
    </div>
    <div class="table-box">
      <a-table
          :columns="columns"
          :data-source="tableList"
          rowKey="id"
          :scroll="{
            y: tableHeight,
            x: tableWidth,
            scrollToFirstRowOnChange: true,
          }"
          :pagination="pagination"
      >
        <template #infoCollapse>
          <div class="collapse-cell">
            <div class="collapse-btn" @click="infoCollapse = !infoCollapse">
              <DoubleLeftOutlined v-if="infoCollapse" />
              <DoubleRightOutlined v-else />
            </div>
          </div>
        </template>
        <template #status="{ record }">
          <a-tag :class="`status ${record.taskStatusText}`">
            {{record.taskStatusText}}
          </a-tag>
        </template>
      </a-table>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  onActivated,
  onMounted, computed
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOptionsCode, useTableHeight } from "@/utils/common";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons-vue";
import {onBeforeRouteLeave, useRoute} from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "resourceTask",
  components: {
    DoubleLeftOutlined,
    DoubleRightOutlined,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const isRMLeader = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("11") !== -1
    })
    const collapse = ref(false);
    const taskTypeOps = ref([]);
    const taskStatusOps = ref([]);
    const formState = ref({
      fullName: "",
      // grade: [],
      // orgThreeTwo: [],
      // orgFourOneOne: [],
      taskType: [],
      taskStatus: ["0", "1"],
      jobStatus: "1",
    });

    const infoCollapse = ref(false);
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3"
        }
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB"
        }
      };
    };
    const columns = computed(() => {
      let infoCols = [];
      if (infoCollapse.value) {
        infoCols = [
          {
            title: "Team",
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 130
          },
          {
            title: "Offering",
            dataIndex: "orgFourOneOne",
            key: "orgFourOneOne",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 230
          },
          {
            title: "Grade",
            dataIndex: "psGroup",
            key: "psGroup",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 100
          },
          {
            title: "GGID",
            dataIndex: "ggid",
            key: "ggid",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 120
          }
        ];
      }
      return [
        {
          title: "姓名",
          dataIndex: "fullName",
          key: "fullName",
          width: 200,
          ellipsis: true,
          fixed: "left"
        },
        {
          dataIndex: "infoCollapse",
          key: "infoCollapse",
          width: 0,
          slots: { title: "infoCollapse" },
          fixed: "left"
        },
        ...infoCols,
        {
          title: "Task Type",
          dataIndex: "taskTypeText",
          key: "taskTypeText",
          width: 150,
          ellipsis: true,
        },
        {
          title: "Task Detail",
          dataIndex: "taskDetailText",
          key: "taskDetailText",
          width: 150,
          ellipsis: true,
        },
        {
          title: "Task状态",
          dataIndex: "taskStatusText",
          key: "taskStatusText",
          width: 100,
          ellipsis: true,
          slots: { customRender: "status" },
        },
        {
          title: "开始时间",
          dataIndex: "beginDate",
          key: "beginDate",
          width: 150,
          ellipsis: true,
        },
        {
          title: "结束时间",
          dataIndex: "endDate",
          key: "endDate",
          width: 150,
          ellipsis: true,
        },
        {
          title: "小时数",
          dataIndex: "hours",
          key: "hours",
          width: 100,
          ellipsis: true,
        },
        {
          title: "Total Hours",
          dataIndex: "totalHours",
          key: "totalHours",
          width: 100,
          ellipsis: true,
        },
        {
          title: "Project Code",
          dataIndex: "projectCode",
          key: "projectCode",
          width: 150,
          ellipsis: true,
        },
        {
          title: "PM",
          dataIndex: "pmName",
          key: "pmName",
          width: 210,
          ellipsis: true,
        }
      ];
    });
    const tableList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共${total}条`;
      },
      onChange: (page, pageSize) => {
        pagination.current = page;
        pagination.pageSize = pageSize;
        getTableList();
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getTableList();
      }
    });
    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const visible = ref(false);
    const activeRow = ref({});
    const activeIndex = ref();

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = {
        condition: formState.value.fullName,
        // grade: formState.value.grade,
        // orgFourOneOne: formState.value.orgFourOneOne,
        // orgThreeTwo: formState.value.orgThreeTwo,
        taskStatus: formState.value.taskStatus,
        taskType: formState.value.taskType,
        // jobStatus: formState.value.jobStatus,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      }
    }

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
          .getDictionaryList({
            params: {
              dictType: "taskStatus,taskType"
            }
          })
          .then((res) => {
            taskStatusOps.value = dictionaryOptionsCode(res.data.taskStatus);
            const taskTypeList = res.data.taskType.filter(item => item.dictCode !== "06");
            taskTypeOps.value = dictionaryOptionsCode(taskTypeList);
          });
    };
    const { $api } = useGlobalPropertyHook();
    const doSearch = () => {
      pagination.current = 1;
      updateParams();
      getTableList();
    };
    const getTableList = () => {
      const data = {
        ...currentParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };

      $api.getConsultantTaskList(data).then((res) => {
        tableList.value = res.data.resource;
        pagination.total = res.data.total;
        pagination.current = res.data.pageIndex + 1;
        pagination.pageSize = res.data.pageSize;
      });
    };
    // 列表内容、分页、筛选项重置
    const resetForm = () => {
      formState.value = {
        fullName: "",
        // grade: [],
        // orgThreeTwo: [],
        // orgFourOneOne: [],
        taskType: [],
        taskStatus: ["0", "1"],
        jobStatus: "1",
      };
      tableList.value = []
      pagination.pageSize = 10
      pagination.current = 1;
      pagination.total = 0;
      updateParams()
      getTableList();
    };

    const collapseClick = () => {
      collapse.value = !collapse.value;
      getSize();
    };

    const taskData = ref();
    const taskUserData = ref();
    const taskUserId = ref();
    const taskTitle = ref("");
    const taskReadonly = ref(false);

    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSelectOptions();
      doSearch();
    });

    onActivated(() => {
      if (route.query.idNumber) {
        formState.value = {
          fullName: route.query.idNumber,
          // grade: [],
          // orgThreeTwo: [],
          // orgFourOneOne: [],
          taskType: [],
          taskStatus: ["0", "1"],
          jobStatus: route.query.jobStatus,
        };
      }
      updateParams()
      getSize();
      getTableList();
    });
    onBeforeRouteLeave(() => {
      pagination.current = 1
    });
    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      collapse,
      taskTypeOps,
      taskStatusOps,
      formState,
      resetForm,
      infoCollapse,
      columns,
      tableList,
      pagination,
      tableHeight,
      tableWidth,
      getTableList,
      doSearch,
      visible,
      activeRow,
      activeIndex,
      collapseClick,
      taskData,
      taskUserData,
      taskUserId,
      taskTitle,
      taskReadonly,
      updateParams,
      isRMLeader,
      isRM
    };
  }
});
</script>

<style lang="less" scoped>

.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;

  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
}


.table-box {
  margin-top: 24px;
  position: relative;

  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 10px;
  }

  .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.未开始 {
      color: #1890FF;
      background: #DBEEFF;
    }
    &.进行中 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已完成 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已失效 {
      color: #DC5E4F;
      background: #FFE7EC;
    }
  }
}

.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d0e2f3;
  color: #3182ce;
  position: absolute;
  left: -28px;
}
</style>
